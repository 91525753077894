.location .box {
  position: relative;
  border-radius: 5px;
  overflow: hidden !important;
}
.location img {
  border-radius: 5px;
  height: 250px;
  object-fit: cover;
  transition: transform 0.3s ease; /* Add this line */
  
}

.location img:hover {
  transform: scale(1.1); 
}
.location .overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 250px;
  width: 100%;
  color: #fff;
  z-index: 222;
}
.location .overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 290px;
  height: 190px;
  background: rgb(15 28 47 / 30%);
  z-index: -1;
  margin: 10px;
  border-radius: 5px;
}
.location h5 {
  font-size: 18px;
  font-weight: 500;
}
.location label {
  color: #fff;
  margin-right: 20px;
  opacity: 0.8;
}
@media screen and (max-width: 800px) {
  .location .overlay::after {
    width: 280px;
    height: 170px;
  }
}
@media (max-width: 768px) {
  .mobile-view {
    display: flex;
    flex-direction: column;
  }
  .mobile-view .box {
    width: 100%;
    margin-bottom: 20px;
  }
}