.footerContact {
  background-color: #faf9f9;

  padding: 2px 0;
  color: #fff;
}
.footerContact h1 {
  font-size: 20px;
  
}
/* footer css */
.footer{
  background-color: #1f1f21;
}

.footer{
  position: relative;
 
}
.readmore{
  color: rgb(26, 91, 242);
}

.footer::before {
  content: "";
  position: absolute;
  background: var(--primary-color);
  left: 0;
  top: 0;
  width: 100%;
  height: 6px;
}

.footer::after {
  content: "";
  position: absolute;
  background: var(--secondry-color);
  left: 30%;
  right: 30%;
  top: 0;
  height: 6px;
}

.footer_overlay{
  background-color: #fafaff;
 padding-top: 40px;
 padding-bottom: 10px;
}

.footer_about_div{
  margin-bottom: 20px;
}

.footer_logo_div{
  margin-bottom: 10px;
  text-align: center;
}

.footer_logo_div img{
  width: 180px;
  margin: auto;
}

.footer_about_div p{
  line-height: 27px;
  font-size: 16px;
  font-weight: 600;
  color: rgb(0, 0, 0);
}

.makeindia{
  text-align: center; 
  display: inline-block;
  margin-bottom: 20px;
}


.makeindia img{
  width: 150px;
  margin: auto;
  display: inline-block;
}

.footer_headti{
  margin-bottom: 20px;
  position: relative;
}

.footer_headti h5{
  position: relative;
  font-weight: 800;
  text-transform: capitalize;
  font-size: 19px;
  padding-bottom: 10px;
  padding-top: 10px;
  color: rgb(0, 0, 0);
}

.footer_headti h5 img{
  width: 20px;
  text-align: center;
  align-items: center;
  margin-top: -12px;
  animation: mover 1s infinite  alternate;
}

.footer_headti ul{
  list-style: none;
  padding: 0px 12px;
}

.footer_headti li{
 
  font-size: 16px;
  border-bottom: 1px dashed black;
  padding: 8px 0px;
 

}

.footer_headti li a{
  color: black;
  transition: .4s ease-in-out;
  font-weight: 600;
}


.footer_headti li a:hover{
  color: var(--primary-color);
  transition: .4s ease-in-out;
  margin-left: 4px;
}

.footer_headti .d-flex{
  margin-bottom: 10px;
}

.footer_headti .flex-shrink-0 .bx{
  font-size: 25px;
}

.footer_headti .footer_num_div p{
  font-size: 16px;
  font-weight: 600;
  line-height: 27px;
  color: black;
  margin-left: 10px;
}

.footer_headti .footer_num_div a{
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 27px;
  color: black;
  margin-left: 10px;
}

.footer_icon_div a{
  width: 25px;
  height: 25px;
  line-height: 25px;
  background-color: var(--primary-color);
  color: var(--white-color);
  font-size: 14px;
  text-align: center;
  margin-top: -10px;
  
}

.footer_icon_div .bxl-instagram{
  width: 25px;
  height: 25px;
  line-height: 25px;
  background-color: var(--secondry-color);
  color: var(--white-color);
  font-size: 14px;
  
}

.footer_icon_div b{
font-weight: 600;
margin-right: 17px;
font-size: 20px;
}

/* footer css end */

/* ======================copyright section start============*/
.copyright_section{
  background-color: var(--white-color);
   }

   .copyright_section p{
      text-align: center;
      padding-top: 10px;
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 700;
      color: black;
   }
 
   .copyright_section p a{
      color: rgb(26, 91, 242);
      font-weight: 800;
   }
/* ======================copyright section end============ */


