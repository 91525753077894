.team .box {
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid rgb(62 28 131 / 10%);
  padding: 1rem;
  transition: 0.5s;
}
.team .box:hover {
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
  cursor: pointer;
}
.team .details {
  text-align: center;
  margin-top: 7px;
}
.team .img {
  width: 90px;
  height: 90px;
  margin: auto;
  position: relative;
}
.team img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}
.team .img i {
  color: #108be7;
  font-size: 17px;
  position: absolute;
  top: 35px;
  right: -10px;
}
.team .img::after {
  content: "";
  position: absolute;
  top: -15px;
  left: -15px;
  width: 110px;
  height: 110px;
  /* border: 5px solid #edf0f5; */
  border-radius: 50%;
}
.team label {
  display: inline-block;
  margin-top: 10px;
}
.team i {
  color: grey;
  margin-right: 5px;
}
.team ul {
  display: inline-block;
  margin-top: 10px;
}
.team ul li {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  align-items: center;
  background-color: #f4f5f7;
  border-radius: 50%;
  margin: 5px;
}
.team .button {
  margin-top: 20px;
}
.team .button i {
  color: white;
  font-size: 20px;
}
.team-slider .slick-prev,
.team-slider .slick-next {
  z-index: 1; /* Make sure the arrows are above the slides */
}

.team-slider .slick-prev:before,
.team-slider .slick-next:before {
  color: #000; /* Change the color of the arrows */
}
