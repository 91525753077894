.about {
  margin-bottom: 30px;
}
.about .heading {
  text-align: left;
  width: 100%;
  margin-bottom: 0px;
}
.about p {
  font-size: 15px;
  padding-right: 30px;
  margin-bottom: 20px;
  line-height: 30px;
}
@media screen and (max-width: 768px) {
  .about .container {
    flex-direction: column;
  }
  .about p {
    padding: 0;
  }
  .about .container img {
    margin-top: 50px;
    padding-right: 10px;
        padding-left: 10px;
        border-radius: 30px;
  }
}
@media screen and (max-width: 768px) {
  .about .container {
    flex-direction: column;
  }
  .about p {
    padding: 0;
    font-size: 16px; /* Adjust as needed */
    line-height: 1.5; /* Adjust as needed */
  }
  .about .container img {
    margin-top: 35px;
    
  }
}
.left {
  text-align: justify;
  margin-left: 10px;
  margin-right: 10px;
}
@media (min-width: 768px) {
  .about-image {
    width: 500px;
    height: 390px;
    margin-top: 80px;
    border-radius: 80px;
  }
}