.awards {
  background-color: #faf9f9;
  color: #000000;
  text-align: center;
}
.awards .heading {
  display: flex;
  flex-direction: column-reverse;
}
.awards .heading p {
  color: #000000;
}
.awards .heading h1 {
  color: #0e0e0e;
  font-size: 25px;
  font-weight: 400;
}
.awards .icon {
  width: 100px;
  height: 100px;
  line-height: 100px;
  margin: auto;
  margin-top: 2px;
  margin-bottom:10px ;
  background: rgb(255, 255, 255);
  border-radius: 4% 50%;
  font-size: 32px;
  color: #6f6d6d;
  
}
.awards h1 {
  font-size: 20px;
}
.awards p {
  color: rgb(0, 0, 0);
}
.content {
  margin-top: 20px; /* Remove margin-top */
  padding-top: 0px; /* Remove padding-top */
  
}
@media (max-width: 768px) {
  .awards .content {
    /* display: flex;
     */
    flex-direction: column;
    align-items: center;
  }
  .box p{
    margin-bottom:0px
  }
  .awards .box {
    width: 100%;
    margin-bottom: 0px;
  }
  .awards .icon {
    width: 100px;
    height: 100px;
    line-height: 100px;
    
    margin-top: 2px;
    margin-bottom:10px ;
    background: rgb(255, 255, 255);
    border-radius: 4% 50%;
    font-size: 32px;
    color: #6f6d6d;
    margin-top: 0px !important;
      margin-left: 30px;
  }
}

