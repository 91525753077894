.site_map_nav {
    margin-bottom: 20px;
  }
  .site_map_nav h2{
    text-align: center;
    padding: 20px;
  }
  .site_map_navi{
    text-align: center;
  }
  .site_map_nav a {
    background-color: #fff;
    color: #212121;
    padding: 10px 20px 10px 20px;
    text-transform: capitalize;
    border-radius: 100px;
    margin: 0px 5px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition: .3s ease-in-out;
    border-left: 5px solid #eeeaea;
    border-right: 5px solid #eeeaea;
    font-size: 15px;
    text-align: center;
  }
  
  /* our product start */
  .sitemap_section {
    padding-top: 20px;
    padding-bottom: 50px;
  }
  .sitemap_head_ti {
    margin-bottom: 10px;
  }
  .sitemap_ul_div ul {
    list-style: none !important;
  }
  .sitemap_ul_div li {
    line-height: 40px;
    border: 1px dashed lightgray;
    margin-bottom: 15px;
    padding: 7px 10px;
    border-left: 5px solid #f36c21;
    background-color: #fff;
    transition: .5s ease;
    display: block;
    font-family: var(--alori-font-2)
  }
  
  .sitemap_ul_div li a {
    text-transform: capitalize;
    font-size: 15px;
    display: inline-block;
    font-family: var(--alori-font-2)
  }
  @media only screen and (max-width: 767px) {
    .site_map_nav a {
        background-color: #fff;
        color: #212121;
        padding: 0;
        text-transform: capitalize;
        border-radius: 100px;
        margin: 0px 5px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        transition: .3s ease-in-out;
        border-left: 5px solid #eeeaea;
        border-right: 5px solid #eeeaea;
        font-size: 15px;
        text-align: center;
    }
}
.site_map_nav {
    margin-bottom: 20px;
  }
  
  .site_map_nav h2 {
    text-align: center;
    padding: 20px;
  }
  
  .site_map_nav .button-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  
  .site-map-btn {
    background-color: #fff;
    color: #212121;
    padding: 10px 20px;
    text-transform: capitalize;
    border-radius: 100px;
    margin-bottom: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition: .3s ease-in-out;
    border-left: 5px solid #eeeaea;
    border-right: 5px solid #eeeaea;
    font-size: 15px;
    text-align: center;
    flex: 0 0 calc(33.333% - 10px); /* Adjust button width */
  }
  
  @media only screen and (max-width: 767px) {
    .site_map_nav .button-row {
      flex-direction: column;
      
    }
  }
/* Mobile view */
@media (max-width: 768px) {
    .button-row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
       
    }

    .button-row a {
        margin-bottom: 10px; /* Add some space between the links */
    }
}
.site-map-btn {
    width: 150px !important; /* Adjust as needed */
    /* Other styles... */
}