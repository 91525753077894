/* Hide the desktop video by default */
.desktop_section_video {
  display: none;
}

/* Show the desktop video and hide the mobile video on viewports wider than 768px */
@media screen and (min-width: 768px) {
  .desktop_section_video {
    display: block;
  }
  .moblie_section_video {
    display: none;
  }
}
