header {
  background-color: #fff;
  /* height: 10vh; */
  box-shadow: 0 5px 30px rgb(0 22 84 / 10%);
  padding: 1px 0;
  position: sticky;
  top: 0;
  z-index: 99999;
}
header .nav {
  margin-top: 16px;
  font-size: 21px;
}
header img {
  width: 130px;
  height: 55px;
  object-fit: contain;
  margin-top: 33px;
  margin-bottom: 3px;
}
header a {
  margin-left: 30px;
  transition: 0.5s;
  font-weight: 500;
}
header a:hover {
  color: #d22f2f;
}
header span {
  width: auto;
  padding: 0px 6px;
  background: #6f7380;
  border-radius: 50px;
  color: #ffffff;
  margin-right: 4px;
  font-weight: 400;
}
header h4 {
  font-weight: 500;
  margin-right: 20px;
}
header .toggle {
  display: none;

}

@media screen and (max-width: 767px) {
  header {
    padding: 0;
  }
  header img {
    /* margin-top: 30px; */
    margin-top: 33px;
    margin-left: -60px;
  }
  header .button {
    display: none;
  }
  header .nav .flex {
    display: none;
  }
  header .toggle {
    display: block;
    margin-top: 20px;
  }
  header .btn mob{
    border: none !important;
  }
  header .small {
    position: absolute;
    top: 9vh;
    left: 0;
    width: 100%;
    background-color: #fbf9f9;
    /* padding: 20px; */
  }
  header li a {
    display: inline-blockce;
    margin-bottom: 20px;
    color: #000000;
    line-height: 40px;
  }
}
/* }
@media screen and (max-width: 414px) {
  header {
    padding: 0;
  }
  header img {
    margin-top: 5px;
    margin-left: -30px;
  }
  header .button {
    display: none;
  }
  header .nav .flex {
    display: none;
  }
  header .toggle {
    display: block;
    margin-top: 20px;
  }
  header .small {
    position: absolute;
    top: 10.5vh;
    left: 0;
    width: 100%;
    background-color: #fbf9f9;
  }
  header li a {
    display: inline-block;
    margin-bottom: 20px;
    color: #000000;
  }
} */
.header {
  background-color: #345; /* Change this to your desired background color */
  color: #fff; /* Change this to your desired text color */
  /* padding: 20px; */
  padding-right: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 24px; /* Adjust font size as needed */
  margin-top: -27px;
  margin-left:125px;
}

.nav-links a {
  color: #fff; /* Change this to your desired link color */
  text-decoration: none;
  margin-left: 20px; /* Adjust spacing between links */
}

.nav-links a:hover {
  text-decoration: underline;
}

.nav-links .active {
  font-weight: bold; /* Style for the active link */
}
.logono{
  font-size: 14px; /* Adjust font size as needed */
  color: #fff; /* Change this to your desired text color */
  /* text-transform: lowercase; */
}
@media (max-width: 768px) {
  .header {
    display: none;
  }
}
.logo-text {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  margin-top: 0px ;
  font-size: 40px; /* Adjust as needed */
  font-weight: bold; /* Adjust as needed */
}
.hide-animation {
  display: none;
}

.logo-text {
  font-size: 40px; /* Adjust as needed */
  font-weight: bold; /* Adjust as needed */
  color: #000; /* Adjust as needed */
}

